// @ts-strict-ignore
import { FC, useMemo } from 'react';

import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';

import classNames from 'classnames';
import debounce from 'debounce-promise';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { TICKET_ACTIVE_STATUS_OPTIONS } from 'models/Ticket';

import SearchBar from 'views/Dashboard/SearchBar';
import {
  AllFilters,
  FilterName,
  FilterProps,
  SearchFiltersType,
  TicketFiltersBaseProps
} from 'views/Filters/filters.types';
import { TasksFilters } from 'views/Filters/TasksFilters';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';

import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';

import {
  AssigneesFilterField,
  RegionsFilterField,
  LocationsFilterField,
  ProvidersFilterField,
  TagsFilterField,
  TicketTypeFilterField
} from './FilterFields';

import './PageFilters.scss';

export const allFiltersToNames: Partial<Record<keyof Partial<AllFilters>, FilterName>> = {
  ticketType: FilterName.ItemTypes,
  providers: FilterName.Providers,
  locations: FilterName.Locations,
  assignees: FilterName.Assignees,
  patientTags: FilterName.PatientTags,
  episodeIds: FilterName.Episodes,
  episodeNumbers: FilterName.EpisodeNumber,
  owners: FilterName.TaskOwner,
  role: FilterName.TaskRole,
  searchTerm: FilterName.SearchByNameOrMrn,
  taskSearchTerm: FilterName.SearchByTask,
  status: FilterName.TaskStatus,
  credentialIds: FilterName.Credentials,
  userTypes: FilterName.UserType,
  userStatuses: FilterName.UserStatus
};

export const TicketFilters: FC<TicketFiltersBaseProps & FilterProps> = ({
  className,
  reducedNumOfFiltersPerRow,
  disableSearchName,
  updateFiltersByKey,
  filters,
  hideTags,
  showTaskFilters
}) => {
  const { settingsStore } = useStores();

  const showSearchBar =
    !disableSearchName && settingsStore.hasFeature(FEATURES.SEARCH_BY_NAME_OR_MRN_FILTER);
  const showPatientTagsFilter = !hideTags && settingsStore.hasFeature(FEATURES.PATIENT_TAGS_FILTER);
  const showProvidersFilter = settingsStore.hasFeature(FEATURES.PROVIDERS_FILTER);
  const showRegionsFilter = settingsStore.hasFeature(FEATURES.REGIONS);
  const showLocationsFilter =
    settingsStore.hasFeature(FEATURES.LOCATIONS_FILTER) && !showRegionsFilter;
  const showAssigneesFilter = settingsStore.hasFeature(FEATURES.ASSIGNEES_FILTER);
  const classes = classNames('page-filters', className, {
    shorten: reducedNumOfFiltersPerRow,
    'full-width': !showTaskFilters
  });

  const onDropdownFilterChange = (
    values: ValueOf<SearchFiltersType>,
    filterKey: keyof SearchFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName);
    updateFiltersByKey(filterKey)(values);
  };

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce(
      (searchQuery: string) => trackSearchByNameOrMrnFilterUsageAnalyticsEvent(searchQuery),
      DEBOUNCE_DELAY
    );
  }, []);

  return (
    <>
      <div className={classes}>
        {showSearchBar && (
          <SearchBar
            className="search"
            placeholder="Search by Name or MRN"
            searchValue={filters.searchTerm || ''}
            onSearchChanged={(value) => {
              onTrackSearchAnalyticsEvent(value);
              updateFiltersByKey('searchTerm')(value);
            }}
            testHook="search-by-name-or-mrn"
          />
        )}

        <TicketTypeFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'ticketType', FilterName.ItemTypes, actionMeta)
          }
          value={filters.ticketType}
        />

        {showPatientTagsFilter && (
          <TagsFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'patientTags', FilterName.PatientTags, actionMeta)
            }
            value={filters.patientTags}
          />
        )}

        {showProvidersFilter && (
          <ProvidersFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'providers', FilterName.Providers, actionMeta)
            }
            value={filters.providers}
          />
        )}

        {showLocationsFilter && (
          <LocationsFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
            }
            value={filters.locations}
          />
        )}

        {showRegionsFilter && (
          <RegionsFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
            }
            value={filters.locations}
          />
        )}

        {showAssigneesFilter && (
          <AssigneesFilterField
            onChange={(values, actionMeta) =>
              onDropdownFilterChange(values, 'assignees', FilterName.Assignees, actionMeta)
            }
            value={filters.assignees}
          />
        )}
      </div>
      <div className={classes}>
        <TasksFilters
          statusFilterOptions={TICKET_ACTIVE_STATUS_OPTIONS}
          filters={filters}
          updateFiltersByKey={updateFiltersByKey}
          onDropdownFilterChange={onDropdownFilterChange}
        />
      </div>
    </>
  );
};
