import { isNumber } from 'lodash/fp';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import { RootStore } from 'mobx/stores';

import ClinicianAlertsFetcher from 'fetchers/ClinicianAlertsFetcher';

import ClinicianAlert, { ConditionType, PopulationType } from 'models/ClinicianAlert';
import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import SpecificCauseCondition, { DxCode } from 'models/Conditions/SpecificCauseCondition';
import Patient from 'models/Patient';
import SeverityReportedCause, { NumericReportedCause, ReportedCause } from 'models/ReportedCause';
import { ProtocolName } from 'models/ScheduledProtocol';

export default class AlertsStore {
  rootStore: RootStore;
  @observable
  alerts: ClinicianAlert[] = [];

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @computed
  get globalAlerts(): ClinicianAlert[] {
    return this.alerts.filter(
      (alert: ClinicianAlert) =>
        // TODO: should be compared to the actual ReportType enum but server returns in Capital
        alert.reportType !== ProtocolName.oralOncolytics &&
        !this.isDxAlert(alert) &&
        !this.isSpecificDrug(alert) &&
        !this.isSpecificRegimen(alert)
    );
  }

  @computed
  private get diagnosisAlerts(): ClinicianAlert[] {
    return this.alerts.filter(
      (alert) => alert.population.type === PopulationType.PatientsWithDiagnosis
    );
  }

  @computed
  get specificDrugAlerts(): ClinicianAlert[] {
    return this.alerts.filter((alert) => this.isSpecificDrug(alert));
  }

  private isDxAlert = (alert: ClinicianAlert) =>
    alert.population.type === PopulationType.PatientsWithDiagnosis;

  @computed
  get specificRegimenAlerts(): ClinicianAlert[] {
    return this.alerts.filter(
      (alert) => alert.population.type === PopulationType.PatientsWithSpecificRegimen
    );
  }

  private isSpecificDrug = (alert: ClinicianAlert): boolean =>
    alert.population.type === PopulationType.PatientsWithSpecificDrug;

  private isSpecificRegimen = (alert: ClinicianAlert): boolean =>
    alert.population.type === PopulationType.PatientsWithSpecificRegimen;

  getClinicianAlerts = async (): Promise<void> => {
    const result = await ClinicianAlertsFetcher.getAlerts();
    runInAction(() => {
      if (JSON.stringify(this.alerts) !== JSON.stringify(result)) {
        this.alerts = result || [];
      }
    });
  };

  removeClinicianAlert = async (id: number): Promise<void> => {
    await ClinicianAlertsFetcher.removeAlert(id);
    await this.getClinicianAlerts();
  };

  submitNewClinicianAlert = async (clinicianAlert: ClinicianAlert): Promise<void> => {
    await ClinicianAlertsFetcher.createAlert(clinicianAlert);
    await this.getClinicianAlerts();
  };

  updateClinicianAlert = async (clinicianAlert: ClinicianAlert): Promise<void> => {
    await ClinicianAlertsFetcher.updateAlert(clinicianAlert);
    await this.getClinicianAlerts();
  };

  isConditionMatchForSymptom = (
    condition: ClinicianAlertCondition,
    symptom: ReportedCause | number
  ): boolean => {
    if (symptom === undefined) {
      return false;
    }

    //Used by symptom operator ticket which doesn't have severity, so we send just the symptom id
    if (isNumber(symptom)) {
      return condition.getId() === symptom;
    }

    return (
      condition.getId() === symptom.causeId &&
      ((symptom as SeverityReportedCause).severity >= condition.getBottomThreshold() ||
        ((symptom as NumericReportedCause).value as number) >= condition.getBottomThreshold())
    );
  };

  private filterAlertConditionsBySymptom(
    alerts: ClinicianAlert[],
    symptom: ReportedCause | number
  ): SpecificCauseCondition[] {
    const conditions: SpecificCauseCondition[] = [];
    alerts.forEach((alert) => {
      alert.conditions.forEach((condition) => {
        if (
          // safety check for data even though alerts should only have specific cause conditions
          condition.type === ConditionType.SpecificCause &&
          this.isConditionMatchForSymptom(condition, symptom)
        ) {
          conditions.push(condition as SpecificCauseCondition);
        }
      });
    });
    return conditions;
  }

  private getDxAlertConditionsForSymptom = (
    symptom: ReportedCause | number,
    patientDxCodes: string[]
  ): SpecificCauseCondition[] => {
    const conditions: SpecificCauseCondition[] = this.filterAlertConditionsBySymptom(
      this.diagnosisAlerts,
      symptom
    );

    if (!conditions || conditions?.length === 0) {
      return [];
    }

    return conditions.filter((condition) => condition.hasMatchingDx(patientDxCodes));
  };

  getDxAlertCodesForSymptom = (
    symptom: SeverityReportedCause | number,
    patientDxCodes: string[]
  ): DxCode[] => {
    const conditions = this.getDxAlertConditionsForSymptom(symptom, patientDxCodes);
    const codes: DxCode[] = [];

    conditions.forEach((condition) => {
      const matchingDxCodes = condition.matchingDxCodes(patientDxCodes);
      codes.push(...matchingDxCodes);
    });

    return codes;
  };

  getDxAlertDescriptionsForSymptom = (
    symptom: ReportedCause | number,
    patientDxCodes: string[]
  ): string[] => {
    const conditions = this.getDxAlertConditionsForSymptom(symptom, patientDxCodes);
    const dxAlertsDescriptions: string[] = [];

    conditions.forEach((condition) => {
      const matchingDxDescriptions = condition.matchingDxCodeDescriptions(patientDxCodes);
      dxAlertsDescriptions.push(...matchingDxDescriptions);
    });

    return dxAlertsDescriptions;
  };

  private getTxAlertsForRegimenId(regimenId: number): ClinicianAlert[] {
    return this.alerts.filter(
      (alert) =>
        alert.population.type === PopulationType.PatientsWithSpecificRegimen &&
        alert.conditions.some((c) => c.regimenId === regimenId)
    );
  }

  getRelevantTxAlertsForSymptom = (symptom: ReportedCause | number, patient: Patient): string[] => {
    let matchedTx: string[] = [];
    const patientRegimen = patient.oralChemoProtocol;
    const patientRegimenId = patientRegimen?.info?.regimenId || patientRegimen?.info?.regimen?.id;

    if (Boolean(patientRegimenId)) {
      const matchedConditions: SpecificCauseCondition[] = this.filterAlertConditionsBySymptom(
        this.getTxAlertsForRegimenId(patientRegimenId),
        symptom
      );

      if (matchedConditions.length) {
        const regimenName = this.rootStore.stores.constantsStore.regimens.items
          .filter((regimen) => regimen.isActive && regimen.id === patientRegimenId)
          .map(({ name }) => name)[0];
        matchedTx.push(regimenName);
      }
    }

    return matchedTx;
  };
}
