// @ts-strict-ignore
import { useCallback } from 'react';

import debounce from 'debounce-promise';
import { first, last } from 'lodash/fp';
import { useForm, useFormContext } from 'react-hook-form';

import { Col, Row } from 'reactstrap';

import { useStores } from 'mobx/hooks/useStores';

import { addDecimalToICDCodeIfNeeded } from 'utils/IcdCodesUtils';
import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { FEATURES } from 'constants/features';

import CallReason, { CallTopicDiscussed, ICallReasonTypes } from 'models/CallReason';

import { IIcdCategory, IIcdCode } from 'models/Icd';
import { PathwayIcdCodeSuggestion, PathwayTemplate } from 'models/PathwayTemplates';
import Patient from 'models/Patient';
import { TicketTypeKind } from 'models/TicketTypes';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { useTicketTypesOptions } from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import {
  FormIcdCodesAsyncMultiAutocomplete,
  FormTicketTypeMultiAutocomplete
} from 'components/UIkit/atoms/Dropdown';
import { ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY } from 'components/UIkit/atoms/Dropdown/Select.shared';

const MAX_TOP_SELECTION_OPTIONS = 20;
const CALL_REMINDER_REASON_TEXT = 'No clinical discussion, just a reminder to report.';
const REMINDER_OPTION = {
  value: new CallReason(ICallReasonTypes.REMINDER, CALL_REMINDER_REASON_TEXT),
  label: CALL_REMINDER_REASON_TEXT
};
const OTHER_REASON_TEXT = 'Other Clinical Discussion';
const reminderCallReason = new CallReason(ICallReasonTypes.REMINDER, CALL_REMINDER_REASON_TEXT);
const otherCallReason = new CallReason(ICallReasonTypes.OTHER, OTHER_REASON_TEXT);

const filterCallReasonOptionByCodeOrText = (search: string) => {
  return (option: ISelectOption<CallReason>): boolean => {
    if (search) {
      return (
        isSubStringCaseInsensitive(option.value.text, search) ||
        isSubStringCaseInsensitive(option.value.icdCodeCode, search)
      );
    }
    return true;
  };
};

const topSelectionSortingFunction = (
  a: { callReason: CallReason; occurrences: number },
  b: { callReason: CallReason; occurrences: number }
) => {
  if (a.occurrences === b.occurrences) {
    return 0;
  }
  return a.occurrences > b.occurrences ? -1 : 1;
};

export const callReasonOptionsToValues = (
  fields: CallReasonSelectionFields
): { callReasons: CallReason[]; topicsDiscussed: CallTopicDiscussed[] } => {
  return {
    callReasons: fields.callReasons ? fields.callReasons.map((option) => option.value) : [],
    topicsDiscussed: fields.topicsDiscussed
      ? fields.topicsDiscussed?.map((topic) => ({
          parentId: topic.parentId,
          name: topic.label,
          id: topic.value,
          parentName: topic.parentName
        }))
      : []
  };
};

export interface CallReasonSelectionFields {
  callReasons: ISelectOption<CallReason>[];
  topicsDiscussed: TicketSubTypeOption[];
}
export const useCallReasonFields = (
  patient: Patient,
  { callReasons, topics }: { callReasons: CallReason[]; topics: TicketSubTypeOption[] } = {
    callReasons: null,
    topics: null
  },
  isEdit: boolean = false
) => {
  const { settingsStore } = useStores();
  const isIcd10CodesRequired = !settingsStore.hasFeature(FEATURES.ICD_10_CODES_OPTIONAL);

  const initialCallReasonValues =
    callReasons && callReasons.length
      ? callReasons.map((callReason) => ({
          value: callReason,
          label: callReason.text
        }))
      : null;

  let reminderInitialValue = patient.isLagging ? [REMINDER_OPTION] : null;

  if (isEdit && !isIcd10CodesRequired) {
    reminderInitialValue = null;
  }

  const defaultValues: CallReasonSelectionFields = {
    callReasons: initialCallReasonValues || reminderInitialValue,
    topicsDiscussed: topics
  };

  const callReasonFieldsMethods = useForm<CallReasonSelectionFields>();
  return { callReasonFieldsMethods, defaultValues };
};

export const CallReasonsFields = ({
  patient,
  openFormMenusUp,
  defaultValues
}: {
  patient: Patient;
  openFormMenusUp?: boolean;
  defaultValues?: CallReasonSelectionFields;
}) => {
  const { constantsStore, pathwaysStore, settingsStore } = useStores();

  const { setValue } = useFormContext();

  const loadOptions = debounce(async (inputValue: string) => {
    const categories = await constantsStore.searchIcdCodeCategories(inputValue);
    return getSelectOptions(categories, inputValue);
  }, ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY);

  const pathwaySuggestions = useCallback(
    (search: string): ISelectOption<CallReason>[] => {
      const res: ISelectOption<CallReason>[] = [];
      Object.values(pathwaysStore.activePathwaysMap).forEach((pathwayData: PathwayTemplate) => {
        if (pathwayData.icdCodesSuggestions) {
          res.push({
            label: `Commonly Used with Pathway: ${pathwayData.name}`,
            options: pathwayData.icdCodesSuggestions
              .map((icdCodeSuggestion: PathwayIcdCodeSuggestion) => {
                const callReason = new CallReason(
                  ICallReasonTypes.ICD_CODE,
                  icdCodeSuggestion.name
                );
                callReason.icdCodeCode = icdCodeSuggestion.code;
                callReason.icdCodeId = icdCodeSuggestion.id;
                return {
                  label: `${addDecimalToICDCodeIfNeeded(callReason.icdCodeCode)} ${
                    callReason.text
                  }`,
                  value: callReason
                };
              })
              .filter(filterCallReasonOptionByCodeOrText(search))
          });
        }
      });
      return res;
    },
    [pathwaysStore.activePathwaysMap]
  );

  const getSelectOptions = (categories: IIcdCategory[], search: string): any => {
    const reminderOption = !search
      ? [
          {
            value: reminderCallReason,
            label: CALL_REMINDER_REASON_TEXT
          }
        ]
      : [];
    const options: ISelectOption<CallReason>[] = [
      ...reminderOption,
      {
        value: otherCallReason,
        label: otherCallReason.text
      },
      ...pathwaySuggestions(search),
      {
        label: 'TOP SELECTIONS',
        options: getTopSelectionCodeIdsForPatient()
          .map((callReason: CallReason) => ({
            value: callReason,
            label: `${addDecimalToICDCodeIfNeeded(callReason.icdCodeCode)} ${callReason.text}`
          }))
          .filter(filterCallReasonOptionByCodeOrText(search))
      }
    ];
    categories.forEach((callReason: IIcdCategory) => {
      options.push({
        label: `${addDecimalToICDCodeIfNeeded(callReason.code)} ${callReason.text}`,
        options: callReason.icdCodes.map((icdCode: IIcdCode) => {
          const callReason = new CallReason(ICallReasonTypes.ICD_CODE, icdCode.text);
          callReason.icdCodeCode = icdCode.code;
          callReason.icdCodeId = icdCode.id;
          return {
            label: `${addDecimalToICDCodeIfNeeded(icdCode.code)} ${icdCode.text}`,
            value: callReason
          };
        })
      });
    });
    return options;
  };

  const getTopSelectionCodeIdsForPatient = () => {
    const map = patient.calls.reduce((topReasons, call) => {
      if (call.callReasons) {
        call.callReasons.forEach((callReason) => {
          if (callReason.type !== ICallReasonTypes.ICD_CODE) {
            return;
          }
          if (!topReasons.has(callReason.icdCodeId)) {
            topReasons.set(callReason.icdCodeId, { callReason, occurrences: 1 });
          } else {
            topReasons.set(callReason.icdCodeId, {
              callReason,
              occurrences: topReasons.get(callReason.icdCodeId).occurrences + 1
            });
          }
        });
      }
      return topReasons;
    }, new Map<number, { callReason: CallReason; occurrences: number }>());

    const topSelectionList: Array<{ callReason: CallReason; occurrences: number }> = [];
    map.forEach((value) => {
      if (topSelectionList.length > MAX_TOP_SELECTION_OPTIONS) {
        return;
      }
      topSelectionList.push({ callReason: value.callReason, occurrences: value.occurrences });
    });
    return topSelectionList.sort(topSelectionSortingFunction).map((codeObj) => codeObj.callReason);
  };

  const topicOptions = useTicketTypesOptions({
    // do not show deleted types which no are not used by any ticket, OR symptom ticket
    filterFn: (node) => node.isInUse && node.kind !== TicketTypeKind.symptom
  });

  const onIcdCodeChange = (newValues: ISelectOption<CallReason>[]) => {
    if (newValues && newValues.length) {
      const lastSelectedValueIsReminder = last(newValues).value.isReminder;
      const valuesSelectedButReminderIsSet =
        newValues.length > 1 && first(newValues).value.isReminder;

      if (lastSelectedValueIsReminder) {
        return setValue('callReasons', [REMINDER_OPTION]);
      }
      if (valuesSelectedButReminderIsSet) {
        newValues.shift();
        return setValue('callReasons', newValues);
      }
    }
  };

  const formattedCallReasonsDefaultValues = defaultValues.callReasons?.map(({ value }) => ({
    label: `${addDecimalToICDCodeIfNeeded(value?.icdCodeCode)} ${value?.text}`,
    value
  }));

  const isIcd10CodesRequired = !settingsStore.hasFeature(FEATURES.ICD_10_CODES_OPTIONAL);

  return (
    <Row className="call-reasons-view">
      <Col>
        <FormIcdCodesAsyncMultiAutocomplete
          label={`ICD-10 Codes${isIcd10CodesRequired ? '' : ' (Optional)'}`}
          onChange={onIcdCodeChange}
          testHook="testHook"
          name="callReasons"
          defaultValue={formattedCallReasonsDefaultValues}
          isRequired={isIcd10CodesRequired}
          loadOptions={loadOptions}
          placeholder="Search By Symptom or Code"
          getOptionValue={(option: ISelectOption<CallReason>) =>
            option.value.icdCodeCode + option.value.text
          } // To make the options unique
          openMenuUp={openFormMenusUp}
        />
      </Col>

      <Col>
        <FormTicketTypeMultiAutocomplete
          label="Topics Discussed (Optional)"
          name="topicsDiscussed"
          options={topicOptions}
          backspaceRemovesValue={false}
          isClearable={false}
          sortAlphabetically={false}
          openMenuUp={openFormMenusUp}
          defaultValue={defaultValues.topicsDiscussed}
        />
      </Col>
    </Row>
  );
};
