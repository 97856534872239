import React, { FC } from 'react';

import { css, styled } from '@mui/material/styles';

import { observer } from 'mobx-react';

import useEllipsis from 'mobx/customHooks/useEllipsis';

import { AlertAttention } from 'models/ClinicianAlert';

import { PathwaySearchResult } from 'models/PathwayTemplates';

import Chip from 'components/Chips/Chip';

import Icon from 'components/Icons/Icon';

import AlertTag, { TagPrefix } from 'components/Ticket/TicketRow/AlertTag';
import { Highlighter } from 'components/Tooltip/Highlighter';

import './PathwayWithDxAlertOption.scss';

interface Props {
  pathway: PathwaySearchResult;
  tags: string[];
  isVisible: boolean;
  alertSeverity: number;
  searchValue: string;
  keywords?: string[];
}

const PathwayWithDxAlertOption: FC<Props> = ({
  pathway,
  tags,
  isVisible,
  alertSeverity,
  searchValue,
  keywords = []
}) => {
  const { ref: pathwayNameRef, isEllipsisActive: isPathwayNameEllipsisIsActive } =
    useEllipsis(isVisible);

  return (
    <div className="pathway-with-alert-container">
      <div className="text-truncate">
        <div
          ref={pathwayNameRef}
          className="text-truncate pathway-name"
          title={isPathwayNameEllipsisIsActive ? pathway.name : undefined}
        >
          <Highlighter searchValue={searchValue} textToHighlight={pathway.name} />
        </div>
        {keywords.length > 0 && (
          <StyledKeywords>
            {keywords.map((keyword, index) => (
              <React.Fragment key={keyword}>
                <Highlighter searchValue={searchValue} textToHighlight={keyword} />
                {index + 1 < keywords.length && ', '}
              </React.Fragment>
            ))}
          </StyledKeywords>
        )}
      </div>

      {alertSeverity > 0 && (
        <div className="warning-container">
          {alertSeverity === AlertAttention.ImmediateAttention ? (
            <Icon.Exclamation data-test-hook="warning-high" />
          ) : (
            <div className="red-circle" data-test-hook="warning-low" />
          )}
        </div>
      )}
      {alertSeverity === 0 && (
        <Chip.List classes="alerts-container">
          {tags.map((alertDescription, index) => (
            <AlertTag
              key={`${alertDescription}_${index}`}
              tag={{ prefix: TagPrefix.Dx, text: alertDescription }}
              className="alert-container"
              isVisible={isVisible}
              withSystemTooltip
            />
          ))}
        </Chip.List>
      )}
    </div>
  );
};

const StyledKeywords = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
  `
);

export default observer(PathwayWithDxAlertOption);
