// @ts-strict-ignore
import { action, makeObservable } from 'mobx';

import { PathwayBuilderStore } from 'mobx/stores/PathwayBuilderStore';

import { PatientPageStore } from 'mobx/stores/PatientPageStore';

import { WorkQueueStore } from 'mobx/stores/WorkQueueStore';

import { initErrorHandling } from 'services/errorHandlingService';
import { publicUrlsService } from 'services/publicUrls.service';

import {
  AlertsStore,
  CallLoggingStore,
  CallsStore,
  CareManagementPatientStore,
  CareManagementReportStore,
  CareTimerStore,
  CliniciansStore,
  ConstantsStore,
  DepartmentStore,
  EpisodeManagementStore,
  LocationsStore,
  PathwayStore,
  PatientEpisodesStore,
  ProvidersStore,
  QaStore,
  ReasonsStore,
  SettingsStore,
  TasksStore,
  TicketFiltersStore,
  TicketsStore,
  TicketTypesStore,
  UiStore,
  UserStore
} from '.';

export interface Stores {
  userStore: UserStore;
  alertsStore: AlertsStore;
  constantsStore: ConstantsStore;
  pathwaysStore: PathwayStore;
  ticketsStore: TicketsStore;
  callsStore: CallsStore;
  departmentsStore: DepartmentStore;
  cliniciansStore: CliniciansStore;
  uiStore: UiStore;
  callLoggingStore: CallLoggingStore;
  ticketFiltersStore: TicketFiltersStore;
  ticketTypesStore: TicketTypesStore;
  settingsStore: SettingsStore;
  locationsStore: LocationsStore;
  providersStore: ProvidersStore;
  patientEpisodesStore: PatientEpisodesStore;
  tasksStore: TasksStore;
  episodeManagementStore: EpisodeManagementStore;
  careTimerStore: CareTimerStore;
  careManagementReportStore: CareManagementReportStore;
  careManagementPatientStore: CareManagementPatientStore;
  reasonsStore: ReasonsStore;
  qaStore: QaStore;
  patientPageStore: PatientPageStore;
  workQueueStore: WorkQueueStore;
  pathwayBuilderStore: PathwayBuilderStore;
}

export class RootStore {
  stores: Stores = {
    userStore: null,
    alertsStore: null,
    constantsStore: null,
    pathwaysStore: null,
    ticketsStore: null,
    callsStore: null,
    departmentsStore: null,
    cliniciansStore: null,
    uiStore: null,
    callLoggingStore: null,
    ticketFiltersStore: null,
    ticketTypesStore: null,
    settingsStore: null,
    locationsStore: null,
    providersStore: null,
    patientEpisodesStore: null,
    tasksStore: null,
    episodeManagementStore: null,
    careTimerStore: null,
    careManagementReportStore: null,
    careManagementPatientStore: null,
    reasonsStore: null,
    qaStore: null,
    patientPageStore: null,
    workQueueStore: null,
    pathwayBuilderStore: null
  };

  @action
  resetStores() {
    this.stores.constantsStore.clear();
    this.stores.ticketsStore.resetStore();
    this.stores.patientPageStore.clearRecentSearchedPatients();
    this.stores.workQueueStore.resetStore();
  }

  constructor() {
    makeObservable(this);
    this.stores.alertsStore = new AlertsStore(this);
    this.stores.patientPageStore = new PatientPageStore(this);
    this.stores.workQueueStore = new WorkQueueStore(this);
    this.stores.constantsStore = new ConstantsStore(this);
    this.stores.pathwaysStore = new PathwayStore();
    this.stores.ticketsStore = new TicketsStore(this);
    this.stores.callsStore = new CallsStore(this);
    this.stores.departmentsStore = new DepartmentStore(this);
    this.stores.cliniciansStore = new CliniciansStore(this);
    this.stores.uiStore = new UiStore(this);
    this.stores.callLoggingStore = new CallLoggingStore(this);
    this.stores.ticketFiltersStore = new TicketFiltersStore(this);
    this.stores.ticketTypesStore = new TicketTypesStore(this);
    this.stores.settingsStore = new SettingsStore();
    this.stores.locationsStore = new LocationsStore(this);
    this.stores.providersStore = new ProvidersStore(this);
    this.stores.patientEpisodesStore = new PatientEpisodesStore(this);
    this.stores.tasksStore = new TasksStore(this);
    this.stores.episodeManagementStore = new EpisodeManagementStore(this);
    this.stores.careTimerStore = new CareTimerStore(this);
    this.stores.careManagementReportStore = new CareManagementReportStore(this);
    this.stores.careManagementPatientStore = new CareManagementPatientStore(this);
    this.stores.reasonsStore = new ReasonsStore();
    this.stores.qaStore = new QaStore(this);
    this.stores.pathwayBuilderStore = new PathwayBuilderStore(this);
    this.stores.userStore = new UserStore(this);
    this.initServices();
  }

  initServices = () => {
    initErrorHandling({
      onLogout: () =>
        window.location.assign(`${publicUrlsService.getUrlWithCurrentProtocol('login')}/logout`),
      onDisplayWithPopUp: this.stores.uiStore.postError
    });
  };
}

const rootStore = new RootStore();

export default rootStore;
