// @ts-strict-ignore
import { ConditionType } from 'models/ClinicianAlert';
import QuestionnaireAnswer, { ReportType } from 'models/QuestionnaireAnswer';
import { OralQuestionStatus, OralQuestionType } from 'models/QuestionnaireAnswerOral';

import ClinicianAlertCondition from './ClinicianAlertCondition';

enum OralAlertComparators {
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan'
}

export default class OralConditions extends ClinicianAlertCondition {
  private readonly answer: any;
  private readonly questionType: OralQuestionType;
  private readonly answerStatus: OralQuestionStatus;
  private readonly condition: OralAlertComparators;

  constructor(rawCondition: any) {
    super(ConditionType.Oral);
    this.answer = rawCondition.answer;
    this.questionType = rawCondition.question;
    this.answerStatus = rawCondition.answerStatus;
    this.condition = rawCondition.condition;
    this.regimenId = rawCondition.regimenId;
  }

  getConditionsString(): string {
    return ``; // not implemented at the moment since we don't show oral alerts in alerts table
  }

  isMet(report: QuestionnaireAnswer): boolean {
    if (report.type !== ReportType.Oral) {
      return false;
    }

    if (
      this.answerStatus === OralQuestionStatus.IDontKnow &&
      report.answer.oral.firstPill?.isIDontKnow
    ) {
      return true;
    }
    if (this.answerStatus === OralQuestionStatus.Answered) {
      if (this.condition === OralAlertComparators.EQUALS) {
        return this.answer === report.answer.oral[this.questionType]?.value;
      }
      if (this.condition === OralAlertComparators.GREATER_THAN) {
        return this.answer < report.answer.oral[this.questionType]?.value;
      }
    }
  }

  getBottomThreshold(): number {
    return 0;
  }

  getId(): number {
    return null;
  }

  setBottomThreshold(): void {}
}
