// @ts-strict-ignore

import { FC, useMemo, useState, MouseEvent } from 'react';

import { Box } from '@mui/material';

import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';
import { Dialogs } from 'analytics/events/dialog';
import { trackSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { observer } from 'mobx-react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import Patient from 'models/Patient';

import { useToggle } from 'hooks/useToggle';
import { useWqSection } from 'hooks/useWqSection';

import SmsBlockedPopup from 'views/Modals/SmsBlockedPopup';

import { StyledSectionActionsContainer } from 'views/WorkQueue/WorkQueue.styled';

import { WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import WqCollapsibleSection from 'views/WorkQueue/WqCollapsibleSection';
import { WqSectionControl } from 'views/WorkQueue/WqSectionControl';
import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import { CardRow } from 'components/Surfaces/Card';
import OverduePatientReportRow from 'components/Ticket/TicketRow/OverduePatientReportRow';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';
import { useSmsModal } from 'components/Ticket/TicketsContainers/TicketsContainers.shared';
import { SimpleTooltip } from 'components/Tooltip';
import { OutlinedButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

const WqOverdueReportsSection: FC = () => {
  const { items, totalItems, totalPages, currentPage, isSectionPageLoading } = useWqSection(
    WorkQueueSectionName.OverdueReports
  );
  const { workQueueStore } = useStores();
  const [isRequestAllOverdueReportsLoading, setIsRequestAllOverdueReportsLoading] = useState(false);
  const [isRequestAllOverdueReportsPopupOpen, setIsRequestAllOverdueReportsPopupOpen] =
    useState(false);
  const [smsModal, toggleSmsModal] = useSmsModal();
  const smsBlockedPopup = useToggle(false);

  const patientIdsToSendRequest = useMemo(
    () =>
      workQueueStore.sectionDataMap[WorkQueueSectionName.OverdueReports].items
        .filter((overdueItem) => {
          const patient = workQueueStore.patientsMap.get(overdueItem.itemData.patientId)!;
          return patient.shouldSendReportReminder;
        })
        .map((overdueItem) => overdueItem.itemData.patientId),
    [workQueueStore.sectionDataMap, workQueueStore.patientsMap]
  );

  const onRequestFromAll = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (patientIdsToSendRequest.length > 0) {
      setIsRequestAllOverdueReportsPopupOpen(true);
    }
  };

  const handleRequestFromAll = async () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.RequestReport,
      value: workQueueStore.sectionDataMap[WorkQueueSectionName.OverdueReports].totalItems,
      type: 'by protocol',
      page_number: currentPage
    });

    setIsRequestAllOverdueReportsLoading(true);

    try {
      await workQueueStore.sendBulkReportRequest(patientIdsToSendRequest);
      workQueueStore.setSectionCurrentPage(WorkQueueSectionName.OverdueReports, 0);
      workQueueStore.handleSectionPageChange(WorkQueueSectionName.OverdueReports);
    } catch (error) {
      throw error;
    } finally {
      setIsRequestAllOverdueReportsLoading(false);
      setIsRequestAllOverdueReportsPopupOpen(false);
    }
  };

  return (
    <>
      <MessageDialog
        id={Dialogs.RequestAllOverdueReports}
        testHook={workQueueTestSelectors.requestAllOverdueReportsPopup}
        isOpen={isRequestAllOverdueReportsPopupOpen}
        handleClose={() => setIsRequestAllOverdueReportsPopupOpen(false)}
        title={`Request ${patientIdsToSendRequest.length} Overdue Reports`}
        primaryActionProps={{
          text: isRequestAllOverdueReportsLoading ? 'Sending...' : 'Request All',
          disabled: isRequestAllOverdueReportsLoading,
          onClick: handleRequestFromAll
        }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: () => setIsRequestAllOverdueReportsPopupOpen(false)
        }}
      >
        Are you sure you want to request a report from all eligible patients on the current page of
        the “Overdue Reports” section?
      </MessageDialog>

      <div className="report-section">
        {smsModal}
        <SmsBlockedPopup isOpen={smsBlockedPopup.isOpen} onCancelClicked={smsBlockedPopup.toggle} />

        <WqCollapsibleSection
          sectionName={Section.Overdue}
          totalItems={totalItems}
          onTrigger={(isOpen) =>
            trackSectionUsageAnalyticsEvent({
              action: isOpen ? AnalyticEventAction.Collapse : AnalyticEventAction.Expand,
              value: Section.Overdue,
              page_number: currentPage
            })
          }
          testHook="overdue-reports-section"
        >
          <StyledSectionActionsContainer>
            <SimpleTooltip
              disabled={patientIdsToSendRequest.length > 0}
              title={
                <Box p={16}>
                  Patients on this page have already received requests. Consider Log Call or Snooze,
                  instead.
                </Box>
              }
              placement="bottom"
            >
              <OutlinedButton
                onClick={onRequestFromAll}
                testHook={workQueueTestSelectors.requestAllOverdueReportsButton}
                disabled={patientIdsToSendRequest.length === 0}
              >
                Request from All
              </OutlinedButton>
            </SimpleTooltip>

            {totalPages > 1 && (
              <WqSectionControl sectionName={WorkQueueSectionName.OverdueReports} />
            )}
          </StyledSectionActionsContainer>

          <div className="tickets-list">
            <TransitionGroup>
              {items.map(({ itemData }, index) => {
                const patient = workQueueStore.getPatientModel(itemData.patientId);

                return (
                  <CSSTransition
                    key={itemData.patientId}
                    classNames="connect-ticket-transition"
                    timeout={600}
                  >
                    {isSectionPageLoading ? (
                      <WqTicketPlaceholder />
                    ) : (
                      <CardRow>
                        <OverduePatientReportRow
                          patient={patient as Patient}
                          toggleSmsModal={(shouldOpenSmsBlockedPopup, patient) =>
                            shouldOpenSmsBlockedPopup
                              ? smsBlockedPopup.toggle()
                              : toggleSmsModal(patient)
                          }
                          withPatientLink
                          ticketIndex={index}
                          ticketSectionCurrentPage={currentPage - 1}
                        />
                      </CardRow>
                    )}
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </div>

          {totalPages > 1 && (
            <WqSectionControl sectionName={WorkQueueSectionName.OverdueReports} isBottom />
          )}
        </WqCollapsibleSection>
      </div>
    </>
  );
};

export default observer(WqOverdueReportsSection);
