// @ts-strict-ignore
import { FC, ReactNode, useState } from 'react';

import { Box } from '@mui/material';

import { isEmpty } from 'lodash/fp';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';
import { getMissedDosesReasonsText } from 'utils/OralOncoUtils';
import { pluralize } from 'utils/StringUtils';

import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import { ReportQuestionAnswer, OralReport } from 'models/QuestionnaireAnswerOral';

import LabelDatePicker from 'components/DateTimePicker/LabelDateTimePicker';
import { usePatientModel } from 'components/Patient/usePatientModel';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
}
function getRemainingPillsText(pillsLeft: ReportQuestionAnswer<number>) {
  if (pillsLeft.isIDontKnow) {
    return 'Pills Remaining: Unknown';
  }
  const { value } = pillsLeft;
  return `${value} ${pluralize('Pill', value)} Remaining`;
}

const OralReportCycle: FC<{ reportId: number; oral: OralReport; patientId: number }> = ({
  reportId,
  oral,
  patientId
}) => {
  const { firstPill, startOfCycle } = oral;
  const [selectedDate, setSelectedDate] = useState<Date>(startOfCycle);
  const { patientPageStore } = useStores();
  const patient = usePatientModel(patientId);

  const handleDateSelect = async (date: Date) => {
    await patientPageStore.updateStartOfCycle(reportId, date);
    const questionnaireAnswer = patient.questionnairesAnswers.find(
      (questionnaire) => questionnaire.id === reportId
    );

    questionnaireAnswer.answer.oral.cycle = date;
    setSelectedDate(date);
  };

  if (firstPill.isAnswered) {
    return <span>{formatDate(firstPill.value, 'MMMM D, YYYY')}</span>;
  }
  return (
    <LabelDatePicker date={selectedDate} onSelect={handleDateSelect} placeholder="Date Unknown" />
  );
};

interface OralReportRowProps {
  children: ReactNode;
  title: string;
}

const OralReportRow: FC<OralReportRowProps> = ({ title, children }) => (
  <Box display="flex">
    <span className="oral-report-row-title">{title}:&nbsp;</span>
    {children}
  </Box>
);

const OralReportSummary: FC<Props> = ({ report, patient }) => {
  const { constantsStore } = useStores();
  const { oral } = report.answer;

  const getOralSummary = () => {
    if (oral.medReceived?.isAnswered && !oral.medReceived.isOk) {
      return <OralReportRow title="Medication Filled">No</OralReportRow>;
    }

    const {
      regimenAsPrescribed,
      firstPill,
      pillsLeft,
      changesInOtherMeds,
      missedDoses,
      previousReportDate
    } = oral;

    const missedDosesReasonsText = getMissedDosesReasonsText(
      missedDoses,
      constantsStore.missedDosesReasonsMap
    );

    return (
      <>
        {regimenAsPrescribed?.isAnswered && !regimenAsPrescribed.isOk && (
          <OralReportRow title="Regimen Adherence Issue">
            {regimenAsPrescribed.customMessage
              ? regimenAsPrescribed.customMessage
              : 'No Info Provided'}
          </OralReportRow>
        )}

        {firstPill?.isAnsweredOrDontKnow && (
          <OralReportRow title="Start of Cycle">
            <OralReportCycle reportId={report.id} oral={oral} patientId={patient.id} />
            {pillsLeft && <>&nbsp;({getRemainingPillsText(pillsLeft)})</>}
          </OralReportRow>
        )}

        {pillsLeft?.isAnsweredOrDontKnow && !firstPill?.isAnsweredOrDontKnow && (
          <OralReportRow title="Remaining Pills">
            {pillsLeft.isAnswered ? pillsLeft.value : 'Unknown'}
          </OralReportRow>
        )}

        {changesInOtherMeds?.isAnswered && !changesInOtherMeds.isOk && (
          <OralReportRow title="Other Medication Change">
            {changesInOtherMeds.customMessage
              ? changesInOtherMeds.customMessage
              : 'No Info Provided'}
          </OralReportRow>
        )}

        {((missedDoses?.isAnswered && !missedDoses.isOk) || missedDoses?.isIDontKnow) && (
          <OralReportRow title="Missed Doses">
            {missedDoses.isIDontKnow ? (
              'Unknown'
            ) : (
              <>
                {missedDoses.value}
                {` Since ${
                  previousReportDate
                    ? formatDate(previousReportDate, 'MMMM Do, YYYY')
                    : 'Start of Cycle'
                }`}
                {!isEmpty(missedDosesReasonsText) ? ` - ${missedDosesReasonsText}` : ''}
              </>
            )}
          </OralReportRow>
        )}
      </>
    );
  };

  return (
    <>
      <DistressCausesReport report={report} patient={patient} />
      {getOralSummary()}
    </>
  );
};

export default OralReportSummary;
