// @ts-strict-ignore
import { compact } from 'lodash/fp';
import { computed } from 'mobx';

import { ConditionType } from 'models/ClinicianAlert';
import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

export interface DxCode {
  code: string;
  text: string;
}

export default class SpecificCauseCondition extends ClinicianAlertCondition {
  private minSeverity: number;
  private minValue?: number;
  private causeName: string;
  private causeId: number;
  dxCodes?: Map<string, DxCode>;

  constructor(rawCondition: any) {
    super(ConditionType.SpecificCause);
    this.minSeverity = rawCondition.minSeverity;
    this.minValue = rawCondition.minValue;
    this.causeName = rawCondition.causeName;
    this.causeId = rawCondition.causeId;
    this.regimenId = rawCondition.regimenId;
    this.dxCodes = new Map();
    rawCondition.dxCodes?.forEach((dxCode: DxCode) => this.dxCodes.set(dxCode.code, dxCode));
  }

  getConditionsString(): string {
    return `${this.causeName}: ${ClinicianAlertCondition.getSeverityString(
      this.minSeverity
    )} or greater`;
  }

  isMet(report: QuestionnaireAnswer, patient: Patient): boolean {
    let isMatch: boolean = true;

    if (this.dxCodes.size && patient?.dxCodes) {
      isMatch = this.hasMatchingDx(patient.dxCodeIds);
    }

    return (
      isMatch &&
      report.answer.causes &&
      report.answer.causes.some(
        (cause: any) =>
          (cause.severity >= this.minSeverity || cause.value >= this.minValue) &&
          cause.causeId === this.causeId
      )
    );
  }

  matchingDxCodes(dxCodes: string[]) {
    return compact(dxCodes.map((dxCode) => this.dxCodes.get(dxCode)));
  }

  matchingDxCodeDescriptions(dxCodes: string[]) {
    return this.matchingDxCodes(dxCodes).map((code) => code.text);
  }

  hasMatchingDx(dxCodes: string[] = []) {
    return this.matchingDxCodes(dxCodes).length > 0;
  }

  getBottomThreshold(): number {
    return this.minSeverity || this.minValue;
  }

  setBottomThreshold(threshold: number): void {
    this.minSeverity = threshold;
  }

  getId(): number {
    return this.causeId;
  }

  getCauseName(): string {
    return this.causeName;
  }

  @computed
  get getDxCodes(): string[] {
    if (!this.dxCodes) {
      return [];
    }
    return compact(Array.from(this.dxCodes.values()).map((dxCode) => dxCode.code));
  }

  // ***Important***. This is so we avoid sending dxCodes to server. need to remove/update once we have ui for dx alerts
  toJSON() {
    return {
      type: ConditionType.SpecificCause,
      minSeverity: this.minSeverity,
      causeName: this.causeName,
      causeId: this.causeId
    };
  }
}
