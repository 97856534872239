// @ts-strict-ignore
import { useMemo } from 'react';

import capitalize from 'lodash/fp/capitalize';
import { observer } from 'mobx-react';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';
import { defaultTagSort } from 'mobx/stores';

import { USER_TYPE_OPTIONS, UserType } from 'utils/AccountUtils';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { Testable } from 'utils/TypeUtils';

import { FEATURES } from 'constants/features';

import { ClinicianUserStatus } from 'models/Doctor';
import PatientLocation from 'models/PatientLocation';

import PatientProvider from 'models/PatientProvider';

import { TICKET_STATUS_ALL_OPTIONS, TicketStatus } from 'models/Ticket';

import { useClinicianCredentialsOptions } from 'hooks/useClinicianCredentials';
import { useCliniciansAsyncSelect } from 'hooks/useCliniciansAsyncSelect';

import { SPECIAL_FILTERS } from 'views/Filters/filters.constants';

import {
  useEpisodesMetadataOptions,
  useTaskRoleOptions
} from 'views/Pages/EpisodesManagement/CreateEditPatientEpisodeFormFields';
import {
  useEpisodesManagementOptions,
  useEpisodesNumberOptions
} from 'views/Pages/PracticeManagement/Episodes/EpisodeManagementFilters';
import { ISelectOption } from 'views/Widgets/StyledSelect';
import StyledSummaryMultiSelect from 'views/Widgets/StyledSummaryMultiSelect';

import {
  ticketTypesSelectCustomFilter,
  useTicketTypesOptions
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import { TicketTypeMultiAutocomplete } from 'components/UIkit/atoms/Dropdown';
import { AsyncBaseSelect } from 'components/UIkit/atoms/Dropdown/BaseSelect';
import { CheckboxesMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/CheckboxesMultiAutocomplete';

export interface MultipleSelectFilterFieldProps<T> extends Testable {
  value: ISelectOption<T>[];
  onChange: (values: ISelectOption<T>[], actionMeta: ActionMeta<any>) => void;
}

export const TagsFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number>) => {
    const { constantsStore } = useStores();

    const tagOptions = useMemo(() => {
      return constantsStore.activeTags.sort(defaultTagSort).map((tag) => ({
        label: tag.name,
        value: tag.id
      }));
    }, [constantsStore]);

    return (
      <StyledSummaryMultiSelect
        label="Patient Tags"
        options={tagOptions}
        sortAlphabetically={false}
        value={value}
        onChange={onChange}
        testHook="patient-tags-filter"
      />
    );
  }
);

export const LocationsFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number>) => {
    const { locationsStore } = useStores();

    const locationOptions = useMemo(() => {
      return locationsStore.locationsForSelect.map(
        (locationOption: ISelectOption<PatientLocation>) => ({
          label: locationOption.label,
          value: locationOption.value.id
        })
      );
    }, [locationsStore.locationsForSelect]);

    return (
      <StyledSummaryMultiSelect
        label="Locations"
        options={locationOptions}
        value={value}
        onChange={onChange}
        testHook="locations-filter"
      />
    );
  }
);

export const RegionsFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number>) => {
    const { locationsStore } = useStores();
    const options = locationsStore.regionsForSelect;

    return (
      <CheckboxesMultiAutocomplete
        label="Locations"
        value={value}
        onChange={onChange}
        options={options}
        testHook="locations-filter"
      />
    );
  }
);

export const ProvidersFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<PatientProvider>) => {
    const { providersStore } = useStores();
    const providerOptions = providersStore.providersForSelect;
    return (
      <StyledSummaryMultiSelect
        label="Providers"
        options={providerOptions}
        value={value}
        onChange={onChange}
        getOptionValue={(option) => option.value.id}
        testHook="providers-filter"
      />
    );
  }
);

export enum RemoteMonitoringStatus {
  active = 'Active',
  optedOut = 'Opted Out',
  notInvited = 'Not Invited',
  invited = 'Invited',
  awaitingActivation = 'Awaiting Activation'
}

export const AssigneesFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number | SPECIAL_FILTERS>) => {
    const { loadOptions, defaultOptions } = useCliniciansAsyncSelect({
      withUnassignedOption: true
    });

    return (
      <AsyncBaseSelect
        loadOptions={loadOptions}
        value={value}
        onChange={onChange}
        isMulti
        label="Assignees"
        defaultOptions={defaultOptions}
        testHook="assignees-filter"
      />
    );
  }
);

interface TicketTypeFilterFieldProps {
  value: TicketSubTypeOption[];
  onChange: (values: TicketSubTypeOption[], actionMeta: ActionMeta<any>) => void;
}

export const TicketTypeFilterField = observer(({ value, onChange }: TicketTypeFilterFieldProps) => {
  const { settingsStore } = useStores();
  const options = useTicketTypesOptions({
    // do not show deleted types which no are not used by any ticket
    filterFn: (node) => node.isInUse,
    addCategoryAllOption: true,
    addSpecialFilters: true,
    addTicketTypesAndSubTypes: settingsStore.hasFeature(FEATURES.TICKET_TYPES_AND_SUB_TYPES)
  });
  return (
    <TicketTypeMultiAutocomplete
      label="Item Types"
      options={options}
      sortAlphabetically={false}
      value={value}
      onChange={onChange}
      filterOption={ticketTypesSelectCustomFilter}
    />
  );
});

export const ClinicianUserStatusFilterField = ({
  onChange,
  value
}: MultipleSelectFilterFieldProps<ClinicianUserStatus>) => {
  const options = useMemo(() => {
    return Object.values(ClinicianUserStatus).map((status: string) => ({
      label: capitalize(status),
      value: status
    }));
  }, []);

  return (
    <StyledSummaryMultiSelect
      label="User Status"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export const ClinicianCredentialsFilterField = ({
  onChange,
  value
}: MultipleSelectFilterFieldProps<number>) => {
  const clinicianCredentialOptions = useClinicianCredentialsOptions();

  return (
    <StyledSummaryMultiSelect
      label="Credentials"
      options={clinicianCredentialOptions}
      value={value}
      onChange={onChange}
      sortAlphabetically={false} // None should appear first
    />
  );
};

export const UserTypeFilterField = ({
  onChange,
  value
}: MultipleSelectFilterFieldProps<UserType>) => {
  return (
    <StyledSummaryMultiSelect
      label="User Type"
      options={USER_TYPE_OPTIONS}
      value={value}
      onChange={onChange}
    />
  );
};

export const EpisodesFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number>) => {
    const options = useEpisodesMetadataOptions();
    return (
      <StyledSummaryMultiSelect
        label="Episodes"
        options={options}
        value={value}
        onChange={onChange}
        testHook="episodes-filter"
      />
    );
  }
);

interface EpisodeNumberFilterFieldProps extends MultipleSelectFilterFieldProps<number> {
  patientId: number;
}
export const EpisodeNumberFilterField = observer(
  ({ value, onChange, patientId }: EpisodeNumberFilterFieldProps) => {
    const options: any = useEpisodesNumberOptions(patientId);
    return (
      <StyledSummaryMultiSelect
        label="Episode Number"
        options={options}
        isDisabled={options.length === 0}
        value={value}
        onChange={onChange}
      />
    );
  }
);

export const ManageEpisodesFilterField = observer(
  ({ value, onChange }: MultipleSelectFilterFieldProps<number>) => {
    const options = useEpisodesManagementOptions();
    return (
      <StyledSummaryMultiSelect
        label="Episodes"
        options={options}
        value={value}
        onChange={onChange}
      />
    );
  }
);

interface TaskStatusFilterFieldProps extends MultipleSelectFilterFieldProps<TicketStatus> {
  options?: ISelectOption<TicketStatus>[];
}
export const TaskStatusFilterField = ({
  value,
  onChange,
  options = TICKET_STATUS_ALL_OPTIONS
}: TaskStatusFilterFieldProps) => {
  return (
    <StyledSummaryMultiSelect
      label="Task Status"
      options={options}
      value={value}
      onChange={onChange}
      testHook="task-status-filter"
    />
  );
};

export const EpisodeOwnersFilterField = ({
  value,
  onChange
}: MultipleSelectFilterFieldProps<number>) => {
  const { loadOptions, defaultOptions } = useCliniciansAsyncSelect();

  return (
    <AsyncBaseSelect
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      isMulti
      label="Task Owner"
      defaultOptions={defaultOptions}
      testHook="task-owner-filter"
    />
  );
};

export const TaskRolesFilterField = ({
  value,
  onChange
}: MultipleSelectFilterFieldProps<number>) => {
  const options = useTaskRoleOptions();
  return (
    <StyledSummaryMultiSelect
      label="Task Role"
      options={options}
      value={value}
      sortAlphabetically={false}
      onChange={onChange}
      testHook="task-role-filter"
    />
  );
};

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
// options for last name first letter filter - each option is like: { value: 'A', label: 'A' }
const ALPHABETICAL_OPTIONS = ALPHABET.split('').map((letter) => ({
  value: letter,
  label: letter
}));

export const LastNameFirstLetterFilterField = ({
  value,
  onChange
}: MultipleSelectFilterFieldProps<string>) => {
  return (
    <StyledSummaryMultiSelect
      label="Tasks by Last Name"
      options={ALPHABETICAL_OPTIONS}
      value={value}
      sortAlphabetically={false}
      onChange={onChange}
      testHook="last-name-first-letter-filter"
    />
  );
};
